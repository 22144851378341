import React from 'react';
import { Helmet } from 'react-helmet';
import { RiDoubleQuotesL } from 'react-icons/ri';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import webImg from '../assets/images/Web.svg'; // Used for the new services
import appImg from '../assets/images/Mobile app.svg';
import graphicImg from '../assets/images/graphics.svg';
import digitalImg from '../assets/images/Digital.svg';
import bpoImg from '../assets/images/BPO.svg';
import appointmentSettingImg from '../assets/images/Appointment Setting.svg';
import seoImg from '../assets/images/SEO.svg';
import leadgenImg from '../assets/images/Lead Generation.svg';
import coldCallingImg from '../assets/images/Cold Calling.svg';
import php from '../assets/images/php.png';
import angular from '../assets/images/angular.png';
import laravel from '../assets/images/laravel.png';
import python from '../assets/images/python.png';
import node from '../assets/images/node.png';
import react from '../assets/images/react.png';
import fbAdds from '../assets/images/fbAdds.png';
import csharp from '../assets/images/csh.png';
import googlebeAdds from '../assets/images/googleAdds.png';
import flutter from '../assets/images/flutter.png';
import unity from '../assets/images/unity1.png';

const Services = () => {
  const imageUrls = [
    php,
    laravel,
    node,
    react,
    angular,
    python,
    csharp,
    flutter,
    unity,
    googlebeAdds,
    fbAdds,
  ];

  const sliderSettings = {
    dots: true,
    infinite: true,
    arrows: false,
    speed: 400,
    autoplay: true,
    slidesToShow: 4,
    slidesToScroll: 4,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const handleContextMenu = (e) => {
    e.preventDefault(); // This prevents the default context menu from appearing
  };

  return (
    <div>
      <Helmet>
        <title>Services - FastOps</title>
        <meta
          name="description"
          content="We offer sophisticated services in web development, app development, graphic designing, and digital marketing. Our team specializes in PHP, Laravel, Node, React, and more."
        />
      </Helmet>

      <div className="pt-0 bgc">
        <div className="container pt-14 min-h-screen">
          <div className="md:flex items-center justify-between sm:flex flex-col md:flex-row bg-primaryColor py-5 px-3">
            <div className="w-full md:basis-1/2 flex items-center justify-start">
              <h1
                data-aos="fade-up"
                data-aos-duration="1500"
                className="text-[#f3f5f4] font-[900] text-[2.1rem] sm:text-[50px]  w-fit h-fit overflow-hidden fadeOut"
              >
                Our Services
              </h1>
            </div>
            <div className="basis-1/2 mt-10 sm:mt-0 bg-[#f5f3f4] px-3 rounded-l-3xl py-8">
              <div className="sm:text-[50px] text-[#660708] font-[900] text-[2.1rem]">
                <RiDoubleQuotesL className="mt-auto mb-auto" />
              </div>
              <p className="mt-auto mb-auto px-4 text-headingColor font-[500]">
                Code is our canvas, where every keystroke serves others.
              </p>
            </div>
          </div>
          <div className="mt-10 sm:mt-20 md:mt-40">
            <div>
              <div className="md:flex  justify-center gap-x-4">
                {/* Original services */}
                <div className="flex flex-col items-center bg-white border border-gray-200 rounded-lg shadow md:flex-row md:max-w-[50%] px-3 py-2 md:mb-0 mb-4 md:w-1/2  group">
                  <img
                    onContextMenu={handleContextMenu}
                    className=" rounded-t-lg h-[50px] md:rounded-none md:rounded-l-lg  transform  scale-icon"
                    src={webImg}
                    alt="FastOps - Web Development"
                  />
                  <div className="flex flex-col justify-between pl-4 py-4 leading-normal">
                    <h5 className="mb-2 text-2xl font-bold tracking-tight text-primaryColor">
                      Web Development
                    </h5>
                    <p className="mb-3 font-normal text-headingColor">
                      Building websites that work like a charm, making your online dreams a reality with top-notch tech and user-friendliness.
                    </p>
                  </div>
                </div>
                <div className="flex flex-col items-center bg-white border border-gray-200 rounded-lg shadow md:flex-row md:max-w-[50%] px-3 py-2 md:mb-0 mb-4 md:w-1/2 group">
                  <img
                    onContextMenu={handleContextMenu}
                    className=" rounded-t-lg h-[50px] md:rounded-none md:rounded-l-lg transform scale-icon"
                    src={appImg}
                    alt="FastOps - Android App Development"
                  />
                  <div className="flex flex-col justify-between pl-4 py-4 leading-normal">
                    <h5 className="mb-2 text-2xl font-bold tracking-tight text-primaryColor">
                      App Development
                    </h5>
                    <p className="mb-3 font-normal text-headingColor">
                      Developing custom mobile apps for seamless experiences, boosting efficiency and user satisfaction in the digital world.
                    </p>
                  </div>
                </div>
              </div>
              <div className="md:flex  justify-center gap-x-4">
                <div className="flex flex-col items-center bg-white border border-gray-200 rounded-lg shadow md:flex-row md:max-w-[50%] px-3 py-2 md:mb-0 mb-4 md:w-1/2 group">
                  <img
                    onContextMenu={handleContextMenu}
                    className=" rounded-t-lg h-[50px] md:rounded-none md:rounded-l-lg transform scale-icon"
                    src={graphicImg}
                    alt="FastOps - Graphic Designing"
                  />
                  <div className="flex flex-col justify-between pl-4 py-4 leading-normal">
                    <h5 className="mb-2 text-2xl font-bold tracking-tight text-primaryColor">
                      Graphic Designing
                    </h5>
                    <p className="mb-3 font-normal text-headingColor">
                      Creating eye-catching visuals and branding magic to make your online presence unforgettable.
                    </p>
                  </div>
                </div>
                <div className="flex flex-col items-center bg-white border border-gray-200 rounded-lg shadow md:flex-row md:max-w-[50%] px-3 py-2 md:mb-0 mb-4 md:w-1/2 group">
                  <img
                    onContextMenu={handleContextMenu}
                    className=" rounded-t-lg h-[50px] md:rounded-none md:rounded-l-lg transform scale-icon"
                    src={digitalImg}
                    alt="FastOps - Digital Marketing"
                  />
                  <div className="flex flex-col justify-between pl-4 py-4 leading-normal">
                    <h5 className="mb-2 text-2xl font-bold tracking-tight text-primaryColor">
                    Media Strategy
                    </h5>
                    <p className="mb-3 font-normal text-headingColor">
                      Boosting your online mojo through savvy SEO, SEM, and social media tactics, driving business growth like a digital dynamo.
                    </p>
                  </div>
                </div>
              </div>

              {/* Newly added services */}
              {/* <div className="md:flex justify-center gap-x-4">
                <div className="flex flex-col items-center bg-white border border-gray-200 rounded-lg shadow md:flex-row md:max-w-[50%] px-3 py-2 md:mb-0 mb-4 md:w-1/2 group">
                  <img
                    onContextMenu={handleContextMenu}
                    className=" rounded-t-lg h-[50px] md:rounded-none md:rounded-l-lg transform scale-icon"
                    src={coldCallingImg}
                    alt="FastOps - Cold Calling"
                  />
                  <div className="flex flex-col justify-between pl-4 py-4 leading-normal">
                    <h5 className="mb-2 text-2xl font-bold tracking-tight text-primaryColor">
                      Cold Calling
                    </h5>
                    <p className="mb-3 font-normal text-headingColor">
                      Connecting with potential leads directly to help grow your business.
                    </p>
                  </div>
                </div>
                <div className="flex flex-col items-center bg-white border border-gray-200 rounded-lg shadow md:flex-row md:max-w-[50%] px-3 py-2 md:mb-0 mb-4 md:w-1/2 group">
                  <img
                    onContextMenu={appointmentSettingImg}
                    className=" rounded-t-lg h-[50px] md:rounded-none md:rounded-l-lg transform scale-icon"
                    src={appointmentSettingImg}
                    alt="FastOps - Appointment Setting"
                  />
                  <div className="flex flex-col justify-between pl-4 py-4 leading-normal">
                    <h5 className="mb-2 text-2xl font-bold tracking-tight text-primaryColor">
                      Appointment Setting
                    </h5>
                    <p className="mb-3 font-normal text-headingColor">
                      Scheduling appointments efficiently for your sales team to close deals faster.
                    </p>
                  </div>
                </div>
              </div> */}
              <div className="md:flex justify-center gap-x-4">
                <div className="flex flex-col items-center bg-white border border-gray-200 rounded-lg shadow md:flex-row md:max-w-[50%] px-3 py-2 md:mb-0 mb-4 md:w-1/2 group">
                  <img
                    onContextMenu={handleContextMenu}
                    className=" rounded-t-lg h-[50px] md:rounded-none md:rounded-l-lg transform scale-icon"
                    src={seoImg}
                    alt="FastOps - SEO"
                  />
                  <div className="flex flex-col justify-between pl-4 py-4 leading-normal">
                    <h5 className="mb-2 text-2xl font-bold tracking-tight text-primaryColor">
                      SEO
                    </h5>
                    <p className="mb-3 font-normal text-headingColor">
                      Optimizing your online presence to rank higher on search engines and increase visibility.
                    </p>
                  </div>
                </div>
                <div className="flex flex-col items-center bg-white border border-gray-200 rounded-lg shadow md:flex-row md:max-w-[50%] px-3 py-2 md:mb-0 mb-4 md:w-1/2 group">
                  <img
                    onContextMenu={handleContextMenu}
                    className=" rounded-t-lg h-[50px] md:rounded-none md:rounded-l-lg transform scale-icon"
                    src={bpoImg}
                    alt="FastOps - Business Processing Outsource"
                  />
                  <div className="flex flex-col justify-between pl-4 py-4 leading-normal">
                    <h5 className="mb-2 text-2xl font-bold tracking-tight text-primaryColor">
                      Business Processing Outsource
                    </h5>
                    <p className="mb-3 font-normal text-headingColor">
                      Streamlining your business operations by outsourcing administrative and back-office tasks.
                    </p>
                  </div>
                </div>
              </div>
              {/* <div className="md:flex justify-center gap-x-4">
                <div className="flex flex-col items-center bg-white border border-gray-200 rounded-lg shadow md:flex-row md:max-w-[50%] px-3 py-2 md:mb-0 mb-4 md:w-1/2 group">
                  <img
                    onContextMenu={handleContextMenu}
                    className=" rounded-t-lg h-[50px] md:rounded-none md:rounded-l-lg transform scale-icon"
                    src={leadgenImg}
                    alt="FastOps - Lead Generation"
                  />
                  <div className="flex flex-col justify-between pl-4 py-4 leading-normal">
                    <h5 className="mb-2 text-2xl font-bold tracking-tight text-primaryColor">
                      Lead Generation
                    </h5>
                    <p className="mb-3 font-normal text-headingColor">
                      Identifying and cultivating potential clients to expand your business opportunities.
                    </p>
                  </div>
                </div>
              </div> */}
            </div>

            <div className="mt-20 sm:mt-40 mb-4">
              <div className="bg-gradient-to-r from-red-200 via-gray-200 to-slate-200 py-10 rounded-xl overflow-hidden">
                <h2 className="text-headingColor font-[600] text-center border-y-2 border-primaryColor w-fit block mx-auto">
                  Technologies we Work on
                </h2>
                <Slider
                  {...sliderSettings}
                  className="mx-auto h-[30%] flex justify-center items-center mt-10 sm:mt-20"
                >
                  {imageUrls.map((url, index) => (
                    <div key={index} className="focus:outline-none">
                      <img
                        onContextMenu={handleContextMenu}
                        className="w-[45%] mx-auto mt-auto mb-auto focus:outline-none"
                        src={url}
                        alt="FastOps - Technologies to work on"
                      />
                    </div>
                  ))}
                </Slider>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Services;
