import React from 'react'
import { NavLink } from 'react-router-dom'
import errorImg from '../assets/images/error.svg'
const Error = () => {
  const handleContextMenu = (e) => {
    e.preventDefault(); // This prevents the default context menu from appearing
  }
  return (
    <div className="pt-0 bgc">
      <div className="container pt-14 min-h-screen">
        <div className='mt-[20%]'>
          <img src={errorImg} alt="FastOps - Error Page" className='w-[60%] mx-auto fadeIn overflow-hidden' onContextMenu={handleContextMenu} />
          <h3 className='text-center mt-5 text-headingColor font-[700]'>Go Back to <NavLink className='no-underline text-primaryColor hover:underline' to='/'>Home Page</NavLink></h3>
        </div>
      </div>
    </div>
  )
}
export default Error
