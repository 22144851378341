import React, { useState, useRef, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { RiDoubleQuotesL } from 'react-icons/ri';
import Swal from 'sweetalert2';

const Chatboat = () => {
  const chatContainerRef = useRef(null);
  const [typing, setTyping] = useState(false);
  const [messages, setMessages] = useState([
    {
      message: "Hello! Ask me anything",
      sender: "ChatGPT",
    },
  ]);

  const handleSend = async (message) => {
    if (message.trim() === "") {
      Swal.fire({
        title: "Please Ask Something!",
        confirmButtonColor: "#BF0603",
        timer: 2000,
        timerProgressBar: true,
      });
      return;
    }

    const newMessage = {
      message: message,
      sender: "user",
      direction: "outgoing",
    };
    const newMessages = [...messages, newMessage];
    setMessages(newMessages);
    setTyping(true);

    // Call the function to process the message
    await processMessage(newMessages);
  };

  async function processMessage(chatMessages) {
    // Get the current date
    const currentDate = new Date().toLocaleDateString();

    // Display SweetAlert notification with the custom message and current date
    Swal.fire({
      title: "We are working to make it better",
      text: `Please check back later. \nDated: ${currentDate}`,
      confirmButtonColor: "#BF0603",
      icon: "info",
    });

    // Stop typing indication
    setTyping(false);
  }

  useEffect(() => {
    const chatContainer = chatContainerRef.current;
    if (chatContainer) {
      chatContainer.scrollTop = chatContainer.scrollHeight;
    }
  }, [messages]);

  return (
    <div>
      <Helmet>
        <title>AI Chatbot - FastOps</title>
        <meta
          name="description"
          content="Chat with our AI Chatbot for information about FastOps services. FastOps (SMC-Private) Limited specializes in Web Development, Desktop and Android app development, graphic designing, and Digital Marketing."
        />
      </Helmet>
      <div className="pt-0 bgc">
        <div id="askme" className="container pt-14 min-h-screen">
          <div className="md:flex items-center justify-between sm:flex flex-col md:flex-row bg-primaryColor bg-cover py-5 px-3">
            <div className="w-full md:basis-1/2 flex items-center justify-start">
              <h1
                data-aos="fade-up"
                data-aos-duration="1500"
                className="text-[#f5f3f4] font-[900] text-[2.1rem] sm:text-[50px]  w-fit h-fit overflow-hidden fadeOut"
              >
                Our Chatbot
              </h1>
            </div>
            <div className="basis-1/2 mt-10 sm:mt-0 bg-[#f5f3f4] px-3 rounded-l-3xl py-8">
              <div className="sm:text-[50px] text-[#660708] font-[900] text-[2.1rem]">
                <RiDoubleQuotesL className="mt-auto mb-auto" />
              </div>
              <p className="mt-auto mb-auto px-4 text-headingColor font-[500]">
                Programmer's conversational ambassadors.
              </p>
            </div>
          </div>
          <div className="mt-10 sm:mt-20 md:mt-40 mb-4">
            <div
              data-aos="fade-down"
              data-aos-duration="1300"
              data-aos-delay="300"
              className="bg-[#d3d3d3] px-4 py-2 rounded shadow  w-[90%] mx-auto"
            >
              <div className="h-[65vh] overflow-y-auto mb-4" ref={chatContainerRef}>
                <ul>
                  {messages.map((message, i) => (
                    <li
                      key={i}
                      className={`mb-2 ${message.sender === "ChatGPT" ? "text-primaryColor" : "text-headingColor"
                        }`}
                    >
                      {message.message}
                    </li>
                  ))}
                  {typing && <li className="text-smallTextColor italic">Typing...</li>}
                </ul>
              </div>
              <div className="flex justify-center">
                <input
                  className="flex-1 mr-2 border bg-backcolor border-gray-300 rounded p-2 focus:outline-primaryColor"
                  type="text"
                  placeholder="Type your Message"
                  onKeyPress={(event) => {
                    if (event.key === "Enter") {
                      handleSend(event.target.value);
                      event.target.value = "";
                    }
                  }}
                  disabled={typing}
                />
                <button
                  className="px-4 py-2 bg-primaryColor hover:bg-smallTextColor text-white rounded"
                  onClick={() => {
                    const inputElement = document.querySelector("input");
                    handleSend(inputElement.value);
                    inputElement.value = "";
                  }}
                  disabled={typing}
                >
                  Send
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Chatboat;
