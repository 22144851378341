import React from 'react';
import { Helmet } from 'react-helmet';

const PrivacyPolicy = () => {
  const termsContainerStyle = {
    backgroundColor: '#a4161a',
    color: '#f5f3f4',
    padding: '2rem',
    borderRadius: '8px',
    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.2)',
  };

  return (
    <div>
      <Helmet>
        <title>Terms and Conditions & Privacy Policy - FastOps</title>
        <meta
          name="description"
          content="Read our Terms and Conditions and Privacy Policy to understand how we handle your personal information and the rules governing your use of our services."
        />
      </Helmet>
      <section className="py-10 bgc">
        <div className="container pt-14 min-h-screen">
          <h2 className="text-headingColor text-center font-[600] text-[30px] sm:text-[35px]">
            Terms and Conditions & Privacy Policy
          </h2>
          <div style={termsContainerStyle}>
            {/* Terms and Conditions Section */}
            <h3 className="text-headingColor font-[600] text-[25px] sm:text-[30px] mt-6 mb-4">Terms and Conditions</h3>
            <li className="text-[#f5f3f4] font-[500] text-[20px] leading-7 text-justify mb-4">
              <strong>Agreement Overview:</strong> These Terms govern your use of our website and services, establishing an agreement between FastOps LLC ("we," "us") and the user ("you").
            </li>
            <li className="text-[#f5f3f4] font-[500] text-[20px] leading-7 text-justify mb-4">
              <strong>Links to Other Websites:</strong> We are not responsible for the content of third-party sites linked from our website. Please review their privacy policies.
            </li>
            <li className="text-[#f5f3f4] font-[500] text-[20px] leading-7 text-justify mb-4">
              <strong>Communication:</strong> We may contact you via SMS, email, or phone for updates or promotions. You can opt out at any time by replying 'STOP'.
            </li>

            {/* Privacy Policy Section */}
            <h3 className="text-headingColor font-[600] text-[25px] sm:text-[30px] mt-6 mb-4">Privacy Policy</h3>
            <li className="text-[#f5f3f4] font-[500] text-[20px] leading-7 text-justify mb-4">
              <strong>Commitment to Privacy:</strong> We prioritize your privacy and explain here how we collect, use, and protect your personal data.
            </li>
            <li className="text-[#f5f3f4] font-[500] text-[20px] leading-7 text-justify mb-4">
              <strong>Information We Collect:</strong> Personal data such as your name, email, and phone number may be collected when you provide it voluntarily (e.g., forms, emailing us).
            </li>
            <li className="text-[#f5f3f4] font-[500] text-[20px] leading-7 text-justify mb-4">
              <strong>How We Use Your Information:</strong> We use your information to:
              <ul className="list-disc ml-4">
                <li>Enhance your experience on our site.</li>
                <li>Respond to inquiries and provide services.</li>
                <li>Send you promotional materials if opted in.</li>
              </ul>
            </li>
            <li className="text-[#f5f3f4] font-[500] text-[20px] leading-7 text-justify mb-4">
              <strong>Data Security:</strong> While we implement security measures, we cannot guarantee the complete security of data transmitted over the internet.
            </li>
            <li className="text-[#f5f3f4] font-[500] text-[20px] leading-7 text-justify mb-4">
              <strong>Third-Party Links:</strong> We are not responsible for third-party sites linked from our website. Please review their privacy policies.
            </li>
            <li className="text-[#f5f3f4] font-[500] text-[20px] leading-7 text-justify mb-4">
              <strong>Your Rights:</strong> You can access, correct, or delete your personal data and opt out of promotional messages by replying 'STOP' or unsubscribing via email.
            </li>

            {/* 10DLC Compliance Section */}
            <li className="text-[#f5f3f4] font-[500] text-[20px] leading-7 text-justify mb-4">
              <strong>Sharing of Information with Third Parties:</strong> We do not share mobile numbers or text messaging consent with third parties for marketing. Consent is exclusive to FastOps LLC.
            </li>
            <li className="text-[#f5f3f4] font-[500] text-[20px] leading-7 text-justify mb-4">
              <strong>Exclusions for Text Messaging Data:</strong> Text messaging opt-in data and consent will not be shared or used by third parties.
            </li>

            <li className="text-[#f5f3f4] font-[500] text-[20px] leading-7 text-justify mb-4">
              <strong>Changes to this Policy:</strong> We may update this policy, and changes will be posted here with an updated "Last Revised" date.
            </li>
            <li className="text-[#f5f3f4] font-[500] text-[20px] leading-7 text-justify mb-4">
              <strong>Contact Us:</strong> For any questions about this policy or your personal data, please contact us.
            </li>

            {/* Last Updated Section */}
            <p className="text-[#f5f3f4] font-[500] text-[18px] text-center mt-8">
              Last Updated: October 24, 2024
            </p>
          </div>
        </div>
      </section>
    </div>
  );
};

export default PrivacyPolicy;
