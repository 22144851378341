import {useEffect, useState} from 'react'
import Aos from 'aos'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import Header from './components/Header';
import Home from './components/Home'
import Footer from './components/Footer';
import PrivacyPolicy from './components/PrivacyPolicy';
import Services from './components/Services';
import About from './components/About';
import PasswordGenerator from './components/play/PasswordGenerator';
import ColorPaletteGenerator from './components/play/ColorPaletteGenerator';
import Contact from './components/Contact';
import Chatboat from './components/play/Chatboat';
import Error from './components/Error';
import Loader from './components/Loader';







function App() {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    Aos.init();

    setTimeout(() => {
      setIsLoading(false);
    }, 3000);
  }, []);

  return (
    <Router>

{isLoading ? (
          <Loader />
        ) : (
          <>


            <Header />
            <Routes>
              <Route path="/" element={<Home />} />
              < Route path='/privacy-policy'  element={<PrivacyPolicy/>}/>
               <Route path="/services" element={<Services />} />
              <Route path="/about" element={<About />} />
              <Route path="/chatbot" element={<Chatboat />} />
              <Route path="/password-generator" element={<PasswordGenerator />} />
              <Route path="/color-palette-generator" element={<ColorPaletteGenerator />} />
              <Route path="/contact" element={<Contact />} />
              <Route path="*" element={<Error />} /> 
            </Routes>
            <Footer />
          </>

        )}
    </Router>
  );
}

export default App;
